import React, { useCallback, useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Image from 'next/image';
import classnames from 'classnames';
import { Carousel } from 'react-responsive-carousel';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  Tabs,
  Tab,
  GameButton,
  Button,
  List,
  ListItem,
  Last,
  Accordion,
  Chip,
  Skeleton,
  Pagination,
  OutAmountItem,
  ItemListSchemaOrg,
} from 'bf-ui';

import styles from '/src/assets/styles/pages/home.page.scss';

import { getCategories_req, getSlider_req } from '../../../api/home.api';
import { getGames_req } from '../../../api/game.api';

import websiteConfig from '../../../configs/websiteConfig';

import NoGameImg from '../../../assets/images/additional/no-game-image.jpg';
import { setCategories } from '../../../redux/global.slice';

const CATEGORY_KEYS = {
  PROVIDERS: 'providers',
};

const shimmer = (w, h) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#293746" offset="20%" />
      <stop stop-color="#1c2630" offset="50%" />
      <stop stop-color="#293746" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#293746" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

const toBase64 = (str) =>
  typeof window === 'undefined'
    ? Buffer.from(str).toString('base64')
    : window.btoa(str);

export default function HomeSection(props) {
  const router = useRouter();
  const { locale } = router;
  const intl = useIntl();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { isMobile, categories, operators, providers } = useSelector(
    (state) => state.global
  );

  const { winners, withdraws } = useSelector((state) => state.recent);

  const { gamesOnPage } = props;

  const [categoryIndex, setCategoryIndex] = useState(
    categories.findIndex((category) => category.Key === props.category)
  );

  const [gamesLoading, setGamesLoading] = useState(false);
  const [sliderData, setSliderData] = useState(props.sliderData);
  const [bigSliderData, setBigSliderData] = useState(null);
  const [gamesCount, setGamesCount] = useState(props?.games?.count || 0);
  const [games, setGames] = useState(props?.games?.items);
  const [providerName, setProviderName] = useState(props.providerName || '');
  const [page, setPage] = useState(props.page - 1);
  const [lastActiveTab, setLastActiveTab] = useState(0);

  const loadSliderData = useCallback(async () => {
    try {
      const response = await getSlider_req({
        Key: 'home_slider',
        IsMobile: isMobile,
        culture: locale,
      });

      setSliderData(response);
    } catch (e) {
      console.error('HomeSection -> loadSliderData Error:', e);
    }
  }, []);

  const loadBigSliderData = useCallback(async () => {
    try {
      const response = await getSlider_req({
        Key: 'home_super',
        IsMobile: isMobile,
        culture: locale,
      });

      setBigSliderData(response[0]);
    } catch (e) {
      console.error('HomeSection -> loadBigSliderData Error:', e);
    }
  }, []);

  const loadGames = useCallback(async () => {
    try {
      setGamesLoading(true);
      const categoryId = categories[categoryIndex]?.ID;

      if (!categoryId) {
        return;
      }

      let providerId = null;

      if (
        providerName &&
        categories[categoryIndex]?.Key === CATEGORY_KEYS.PROVIDERS
      ) {
        providerId = providers.find(
          (provider) => provider.Name === providerName
        )?.ID;
      }

      const response = await getGames_req(
        {},
        20,
        page + 1,
        providerId,
        categoryId,
        null,
        isMobile
      );

      setGamesCount(response?.count || 1);
      setGames(response?.items ? response.items : null);
    } catch (e) {
      console.error('HomeSection -> loadGames Error:', e);
    } finally {
      setGamesLoading(false);
    }
  }, [categories, categoryIndex, providerName, page]);

  const onCategoryTabChange = useCallback((index) => {
    const categoryKey = categories[categoryIndex]?.Key;

    if (!categoryKey) {
      return;
    }

    setProviderName(
      categoryKey === CATEGORY_KEYS.PROVIDERS ? providers?.[0].Name : ''
    );
    setPage(0);
    setCategoryIndex(index);
  }, []);

  const onProviderChange = useCallback((name) => {
    setPage(0);
  }, []);

  const onPageChange = useCallback(
    ({ selected }) => {
      setPage(selected);
      const categoryKey = categories[categoryIndex].Key;

      const providerQuery =
        providerName && categoryKey === CATEGORY_KEYS.PROVIDERS
          ? `&provider=${providerName}`
          : '';

      const href = `/${categoryKey}/?page=${selected + 1}${providerQuery}`;

      router.push(href, href);
    },
    [categories, categoryIndex, providerName]
  );

  const onPlay = useCallback((key) => {
    router.push(`/games/${key}?game-type=classic`, `/games/${key}`);
  }, []);

  const onPractice = useCallback((key) => {
    router.push(`/games/${key}?game-type=demo`, `/games/${key}`);
  }, []);

  const getCategories = useCallback(async () => {
    try {
      const response = await getCategories_req({}, locale);
      if (response) {
        dispatch(setCategories(response));
      }
    } catch (e) {
      console.error('HomeSection -> getCategories Error: ', e);
    }
  }, [user, locale]);

  const pageHrefBuilder = useCallback(
    (index) => `/?page=${index}`,
    [categories, categoryIndex, providerName]
  );

  useEffect(() => {
    if (!sliderData) {
      loadSliderData();
    }

    if (!bigSliderData) {
      loadBigSliderData();
    }

    if (!games) {
      loadGames();
    }
  }, []);

  useEffect(() => {
    getCategories();
  }, [user?.id, locale]);

  useEffect(() => {
    loadGames();
  }, [categoryIndex, providerName, page]);

  useEffect(() => {
    if (router?.query?.category === 'providers' && router?.query?.provider) {
      if (categories?.length) {
        const index = categories.findIndex(
          (data) => data.Key === router?.query?.category
        );

        if (index !== -1) {
          setCategoryIndex(index);
        }
      }
      setProviderName(router.query.provider);
    }
  }, [router.asPath]);

  useEffect(() => {
    if (!user?.id && router?.asPath === '/my-games') {
      router.push('/', '/');
    }
  }, [user?.id, router?.asPath]);

  const staticSliderData = sliderData?.length
    ? sliderData.filter((slide) => slide.IsStatic)[0] || null
    : null;

  const notStaticSliderData = sliderData?.length
    ? sliderData.filter((slide) => !slide.IsStatic)[0] || null
    : null;

  const notStaticSliderComponent =
    notStaticSliderData?.List.map((slide) => {
      const imgComponent = (
        <picture key={slide.Title}>
          {slide.PathWebP && (
            <source
              srcSet={`${process.env.NEXT_PUBLIC_BASE_URL_V1}/${slide.PathWebP}`}
              type="image/webp"
            />
          )}
          {slide.Path && (
            <source
              srcSet={`${process.env.NEXT_PUBLIC_BASE_URL_V1}/${slide.Path}`}
              type="image/jpeg"
            />
          )}
          <img
            src={`${process.env.NEXT_PUBLIC_BASE_URL_V1}/${slide.Path}`}
            alt={slide.Title}
            key={slide.Title}
            width={390}
          />
        </picture>
      );

      if (slide.Href) {
        return (
          <Link href={slide.Href} as={slide.As} key={slide.Title}>
            <a
              key={slide.Title}
              rel={slide.Rel}
              target={slide.Target}
              className="carousel_link"
            >
              {imgComponent}
            </a>
          </Link>
        );
      }

      return imgComponent;
    }) || [];

  const bigSliderComponent =
    bigSliderData?.List?.map((slide) => {
      const imgComponent = (
        <picture key={slide.Title}>
          {slide.PathWebP && (
            <source
              srcSet={`${process.env.NEXT_PUBLIC_BASE_URL_V1}/${slide.PathWebP}`}
              type="image/webp"
            />
          )}
          {slide.Path && (
            <source
              srcSet={`${process.env.NEXT_PUBLIC_BASE_URL_V1}/${slide.Path}`}
              type="image/jpeg"
            />
          )}
          <img
            src={`${process.env.NEXT_PUBLIC_BASE_URL_V1}/${slide.Path}`}
            alt={slide.Title}
            key={slide.Title}
            width={390}
          />
        </picture>
      );

      if (slide.Href) {
        return (
          <Link
            href={
              slide.Href === '/deposit' && !user?.id
                ? '/login?from=/deposit'
                : slide.Href
            }
            as={slide.Href === '/deposit' && !user?.id ? '/login' : slide.As}
            key={slide.Title}
          >
            <a
              key={slide.Title}
              rel={slide.Rel}
              target={slide.Target}
              className="carousel_link"
            >
              {imgComponent}
            </a>
          </Link>
        );
      }

      return imgComponent;
    }) || [];

  const providerChips = providers.map((provider) => (
    <Link
      key={provider.Name}
      href={`/providers/?provider=${provider.Name}`}
      as={`/providers/?provider=${provider.Name}`}
    >
      <a>
        <Chip
          label={provider.Name}
          selected={providerName === provider.Name}
          onClick={() => onProviderChange(provider.Name)}
        />
      </a>
    </Link>
  ));

  return (
    <>
      <div id="home-page" className="page">
        {isMobile && (
          <div className="carousel_wrapper show_mobile">
            {notStaticSliderData?.List ? (
              <Carousel
                autoPlay
                infiniteLoop
                stopOnHover
                swipeable
                interval={4000}
                showThumbs={false}
                showStatus={false}
                showArrows={false}
              >
                {notStaticSliderComponent}
              </Carousel>
            ) : (
              <></>
            )}
          </div>
        )}
        {categories?.length ? (
          <div className="tabs_wrapper">
            <Tabs value={categoryIndex} onChange={onCategoryTabChange}>
              {categories.map((category) => (
                <div key={category.Key}>
                  <Link href={`/${category.Key}`} as={`/${category.Key}`}>
                    <a>
                      <Tab>
                        <img
                          src={
                            process.env.NEXT_PUBLIC_ENV === 'local' ||
                            category.Icon.includes('http')
                              ? `${process.env.NEXT_PUBLIC_BASE_URL_V1}${category.Icon}`
                              : category.Icon
                          }
                          alt={category.Name}
                          width={24}
                          height={24}
                        />
                        <span>{category.Name}</span>
                        {category.IsNew && (
                          <div className="label_wrapper">
                            {intl.messages.new}
                          </div>
                        )}
                      </Tab>
                    </a>
                  </Link>
                </div>
              ))}
            </Tabs>
          </div>
        ) : (
          <></>
        )}

        {categories[categoryIndex]?.Key === CATEGORY_KEYS.PROVIDERS &&
        providers?.length ? (
          <div className="providers_wrapper">
            {isMobile ? <Accordion>{providerChips}</Accordion> : providerChips}
          </div>
        ) : (
          <></>
        )}
        <div
          className={classnames(
            'home_content',
            categories[categoryIndex]?.Key !== CATEGORY_KEYS.PROVIDERS &&
              'without_providers'
          )}
        >
          {!isMobile && (
            <div className="sidebar left show_desktop">
              <Last
                name={intl.messages.last}
                actions={
                  <>
                    <Button
                      variant={lastActiveTab === 0 ? 'contained' : 'contained2'}
                      onClick={() => setLastActiveTab(0)}
                    >
                      {intl.messages.winners}
                    </Button>
                    <Button
                      variant={lastActiveTab === 1 ? 'contained' : 'contained2'}
                      onClick={() => setLastActiveTab(1)}
                    >
                      {intl.messages.withdraws}
                    </Button>
                  </>
                }
              >
                <List>
                  {lastActiveTab === 0 &&
                    winners.map((data) => (
                      <ListItem key={data.ID}>
                        {data.Key ? (
                          <Link
                            href={`/games/${data.Key}`}
                            as={`/games/${data.Key}`}
                          >
                            <a>
                              <OutAmountItem
                                title={data.Phone}
                                amount={data.Amount}
                                currency={data.Currency}
                                image={`${process.env.NEXT_PUBLIC_BASE_URL_V1}/${data.Media}`}
                              />
                            </a>
                          </Link>
                        ) : (
                          <OutAmountItem
                            title={data.Phone}
                            amount={data.Amount}
                            currency={data.Currency}
                            image={`${process.env.NEXT_PUBLIC_BASE_URL_V1}/${data.Media}`}
                          />
                        )}
                      </ListItem>
                    ))}
                  {lastActiveTab === 1 &&
                    withdraws.map((data) => (
                      <ListItem key={data.ID}>
                        <OutAmountItem
                          title={data.Phone}
                          amount={data.Amount}
                          currency={data.Currency}
                          image={`${process.env.NEXT_PUBLIC_BASE_URL_V1}/${data.Media}`}
                        />
                      </ListItem>
                    ))}
                </List>
              </Last>
            </div>
          )}
          {gamesLoading ? (
            <div className="games_wrapper">
              <div className="games_wrapper_inner">
                {new Array(20).fill().map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Skeleton key={index} />
                ))}
              </div>
            </div>
          ) : (
            <>
              {games?.length ? (
                <div className="games_wrapper">
                  {bigSliderComponent?.length && !isMobile && (
                    <div className="show_desktop big_slider_wrapper">
                      <Carousel
                        autoPlay
                        infiniteLoop
                        stopOnHover
                        swipeable
                        interval={4000}
                        showThumbs={false}
                        showStatus={false}
                        showArrows={false}
                      >
                        {bigSliderComponent}
                      </Carousel>
                      <br />
                    </div>
                  )}

                  <div className="games_wrapper_inner">
                    {games.map((gameData) => (
                      <GameButton
                        key={gameData.Name}
                        NextImage={
                          <Image
                            src={
                              gameData.ImageWebP &&
                              !gameData.ImageWebP.includes('http')
                                ? process.env.NEXT_PUBLIC_BASE_URL_V1 +
                                  gameData.ImageWebP
                                : gameData.ImageWebP
                            }
                            placeholder="blur"
                            blurDataURL={`data:image/svg+xml;base64,${toBase64(
                              shimmer(isMobile ? 128 : 280, isMobile ? 86 : 190)
                            )}`}
                            width={isMobile ? 128 : 280}
                            height={isMobile ? 86 : 190}
                            className="game_img"
                          />
                        }
                        // image={
                        //   process.env.NEXT_PUBLIC_ENV === 'local' &&
                        //   gameData.Image &&
                        //   !gameData.Image.includes('http')
                        //     ? process.env.NEXT_PUBLIC_BASE_URL_V1 +
                        //       gameData.Image
                        //     : gameData.Image
                        // }
                        // imageWebP={
                        //   process.env.NEXT_PUBLIC_ENV === 'local' &&
                        //   gameData.ImageWebP &&
                        //   !gameData.ImageWebP.includes('http')
                        //     ? process.env.NEXT_PUBLIC_BASE_URL_V1 +
                        //       gameData.ImageWebP
                        //     : gameData.ImageWebP
                        // }
                        noImage={NoGameImg.src}
                        withoutName={!isMobile}
                        name={gameData.Name}
                        linkComponent={Link}
                        href={`/games/${gameData.Key}`}
                        as={`/games/${gameData.Key}`}
                        instantPlay
                        bouncingText={gameData.Label}
                        labelText={gameData.StickerTitle}
                        labelTopColor={gameData.StickerColor}
                        labelBottomColor={
                          gameData.StickerBorderColor || gameData.StickerColor
                        }
                        onClick={() => {
                          if (isMobile) {
                            router.push(
                              `/games/${gameData.Key}`,
                              `/games/${gameData.Key}`
                            );
                          }
                        }}
                        onPlay={() => onPlay(gameData.Key)}
                        onPractice={() => onPractice(gameData.Key)}
                      />
                    ))}
                  </div>
                  <Pagination
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={1}
                    pageCount={Math.ceil(gamesCount / gamesOnPage)}
                    previousLabel={intl.messages.prev}
                    onPageChange={onPageChange}
                    forcePage={page}
                  />
                </div>
              ) : (
                <p className="no_games">{intl.messages.no_games}</p>
              )}
            </>
          )}
          {!isMobile && (
            <div className="sidebar right">
              {operators?.length ? (
                <div className="operators_wrapper">
                  <h3>{intl.messages.paybill_numbers}</h3>
                  <List>
                    {operators.map((operatorData) => (
                      <ListItem key={operatorData.Name}>
                        <Image
                          placegolder="blur"
                          src={`${process.env.NEXT_PUBLIC_API_BASE_URL_V1}/media/${operatorData.MediaID}`}
                          width={104}
                          height={27}
                          alt={operatorData.Name}
                        />
                        <Link
                          href={user?.id ? '/deposit' : '/login?from=deposit'}
                          as={user?.id ? '/deposit' : '/login'}
                        >
                          <a>
                            <Button variant="contained">
                              {intl.messages.deposit}
                            </Button>
                          </a>
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                </div>
              ) : (
                <></>
              )}
              {notStaticSliderData?.List ? (
                <div className="carousel_wrapper">
                  <Carousel
                    autoPlay
                    infiniteLoop
                    stopOnHover
                    swipeable
                    interval={4000}
                    showThumbs={false}
                    showStatus={false}
                    showArrows={false}
                  >
                    {notStaticSliderComponent}
                  </Carousel>
                </div>
              ) : (
                <></>
              )}
              {staticSliderData?.List ? (
                <div className="static_slides">
                  {staticSliderData.List.map((slide) => {
                    const ImgComponent = (
                      <picture key={slide.Title}>
                        {slide.PathWebP && (
                          <source
                            srcSet={`${process.env.NEXT_PUBLIC_BASE_URL_V1}/${slide.PathWebP}`}
                            type="image/webp"
                          />
                        )}
                        {slide.Path && (
                          <source
                            srcSet={`${process.env.NEXT_PUBLIC_BASE_URL_V1}/${slide.Path}`}
                            type="image/jpeg"
                          />
                        )}
                        <img
                          src={`${process.env.NEXT_PUBLIC_BASE_URL_V1}/${slide.Path}`}
                          alt={slide.Title}
                          key={slide.Title}
                          width={390}
                        />
                      </picture>
                    );

                    if (slide.Href) {
                      return (
                        <Link href={slide.Href} as={slide.As} key={slide.Title}>
                          <a>{ImgComponent}</a>
                        </Link>
                      );
                    }

                    return ImgComponent;
                  })}
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
        {gamesCount && games ? (
          <ItemListSchemaOrg
            url={websiteConfig.url}
            numberOfItems={gamesCount}
            items={games.map((game) => ({
              name: game.Name,
              image: (game.Image || '').includes('http')
                ? game.Image
                : process.env.NEXT_PUBLIC_BASE_URL_V1 + game.Image,
              url: `${websiteConfig.url}/games/${game.Key}`,
              price: 0,
              currency: websiteConfig.currency,
            }))}
          />
        ) : (
          <></>
        )}
      </div>
      <style jsx>{styles}</style>
    </>
  );
}
